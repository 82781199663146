<template lang="pug">
article.preview
  router-link(:to='{ name: "BlogPost", params: { uid: post.uid } }')
    .post
      span.post__tag {{ post.data.tag }}
      .post__image-wrapper
        prismic-image.post__image-wrapper__image(:field='post.data.image')
      h3.post__title {{ post.data.title[0].text }}
      .post__meta
        span.post__meta__date {{ post.first_publication_date | formatDate }}
      p.post__content {{ shrinkEllipsis(previewContentText, $store.state.windowWidth * 0.3) }}
        span.read-more Lire la suite..
</template>

<script>
import { shrinkEllipsis } from '@/filters/shrinkEllipsis'
import { formatDate } from '@/filters/dateFormatter.js'

export default {
  name: 'BlogPostPreview',
  filters: { shrinkEllipsis, formatDate },
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  computed: {
    previewContentText() {
      return this.post.data.body
        .filter((slice) => slice.slice_type === 'content_')
        .map((slice) => slice.primary.content1)[0]
        .map((content) => content.text)
        .join(' ')
    }
  },
  methods: {
    shrinkEllipsis(value, length) {
      return shrinkEllipsis(value, length)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors';
@import '@/styles/mixins';

a {
  text-decoration: none;
}

.post {
  display: grid;
  grid-template:
    'tag tag' auto
    'image title' auto
    'meta meta' auto
    'content content' auto / 25% 1fr;

  column-gap: 20px;
  row-gap: 10px;
  margin: 1rem 0;

  &__tag {
    color: $orange;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 1.3rem;
    grid-area: tag;
  }

  &__image-wrapper {
    grid-area: image;
    max-width: 100%;
    display: flex;

    &__image {
      max-width: 100%;
      width: 100%;
      height: 70px;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  &__title {
    grid-area: title;
    font-size: 1.5rem;
    line-height: 2rem;
    color: $darkBlue;
  }

  &__meta {
    grid-area: meta;
    font-size: 0.8rem;
    color: rgba($darkBlue, 0.5);
    font-family: 'Libre Franklin', sans-serif;

    &__author {
      margin-right: 0.5rem;
    }
  }

  &__content {
    grid-area: content;
    color: $darkBlue;
  }

  @include medium-screen {
    grid-template:
      'image tag' auto
      'image title' auto
      'image meta' auto
      'image content' auto / 25% 1fr;

    column-gap: 30px;

    &__image-wrapper {
      &__image {
        height: 200px;
      }
    }
  }

  .read-more {
    font-weight: 600;
  }
}
</style>
