export function shrinkEllipsis(value, maxLength) {
  if (value) {
    if (value.length > maxLength) {
      return value.substring(0, maxLength) + '...'
    } else {
      return value
    }
  } else {
    return ''
  }
}
